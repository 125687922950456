import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

declare var $:any;

@Component({
  selector: 'app-videos-blog',
  templateUrl: './videos-blog.component.html',
  styleUrls: ['./videos-blog.component.css']
})
export class VideosBlogComponent implements OnInit {
  url = `https://erp.cal4care.com/cms/cms_data_redirect_voip.php?action=get_omni_mconnectapps&domain_name=omni_mconnectapps.com`;
  items = [];
  video_link;
  constructor(private http: HttpClient) {
    
    this.http.get(this.url).toPromise().then(data => {
      console.log(data[0].data);
      
      for (let key in data)
        if (data.hasOwnProperty(key))
          this.items.push(data[key]);
    });
   }

  ngOnInit(): void {
     $('#video_play').modal('hide');

  } 
 
  showvid(link){
    this.video_link=link;
    // alert(this.video_link);
    $('#video_play').model('show');
    // $('#video_play').modal('hide');
  }


}
