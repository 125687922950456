import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';

// import { CdTimerComponent } from 'angular-cd-timer';


declare var $:any;

declare var iziToast:any;
declare var initBookingsChart: any;
declare var initAgentChart: any;
declare var initAgentCallChart: any;
declare var initAverageWaitChart: any;
declare var initOverallChart: any;
declare var initTotalcallChart: any;
declare var initTotalansChart: any;
declare var initTotalabondChart: any;
declare var initAvgtalkChart: any;
declare var initabondChart: any;
declare var initBusyChart: any;
declare var initAnsweredChart: any;
declare var initLongChart: any;


@Component({
  selector: 'app-custom-wallboard-five',
  templateUrl: './custom-wallboard-five.component.html',
  styleUrls: ['./custom-wallboard-five.component.css']
})
export class CustomWallboardFiveComponent implements OnInit {
 

  // @ViewChild( 'basicTimer', { static: false } ) cdTimer: CdTimerComponent;

  constructor(private serverService: ServerService, private router:Router) {
    

   }
  websocket;
  socketData;
  user_type;
  loginUser;
  show_act_wall = false;
has_admin =false;
has_user =false;
admin_id;
has_hard_id;
countupTimerService;
onCall;
testConfig;
queuesDatas;
agentsDatas;
availDatas;
makebusyDatas;
outgoingDatas;
logoutDatas;
data;
queueData;
addagentsData;
logged_queues=0;
  ngOnInit(): void {
    

 // this.countupTimerService.startTimer();
//  this.cdTimer.start();
    this.user_type = localStorage.getItem('user_type');
    this.loginUser = localStorage.getItem('userId');
    this.admin_id = localStorage.getItem('admin_id');
    
    this.user_type = localStorage.getItem('user_type');
    if(this.user_type == 'Admin'){
      this.has_admin = true;
    } else {
      this.has_user = true;
    }
  
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(this.has_hard_id == ""){
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    } else {
      this.checkLicenseKey();
  }
  


}

startTimer (){
 
}


initSocket() {
  
  this.user_type = localStorage.getItem('user_type');
  this.loginUser = localStorage.getItem('userId');  

  if(this.loginUser == '64'){
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
  } else if(this.loginUser == '201'){
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
  } else {
    this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
  }


  this.websocket.onopen = function(event) { 
    $('#sendonload').click();
    console.log('socket connected');
  }

  this.websocket.onmessage = function(event) {
    var result_message: any = JSON.parse(event.data);
    console.log(result_message);
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(result_message.cust_id == this.has_hard_id || result_message[0].cust_id == this.has_hard_id){
      console.log('matched');
    } else {
      console.log('not matched');
      return false;
    }




      if(result_message[0].data[0].type=="awb5_waiting"){  
        // initBookingsChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initBookingsChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_avlagent"){
        // initAgentChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initAgentChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_agentbusy"){
        initAgentCallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        // initAgentCallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_waitingtime"){
        // initAverageWaitChart(result_message[0].data[0].value,100);
        initAverageWaitChart(result_message[0].data[0].value,100);
       }
      else if(result_message[0].data[0].type=="awb5_overallsla"){
      // initOverallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      initOverallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
       }
      else if(result_message[0].data[0].type=="awb5_total"){
        // initTotalcallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initTotalcallChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_answered"){
       // alert(result_message[0].data[0].value);
        // initTotalansChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initTotalansChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_abandoned"){
        initTotalabondChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        // initTotalabondChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_talktime"){
        initAvgtalkChart(result_message[0].data[0].value,100);
        // initAvgtalkChart(result_message[0].data[0].value,100);
      } 
      else if(result_message[0].data[0].type=="awb5_perabandoned"){
        // initabondChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initabondChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_totmakebusy"){
        // initBusyChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        initBusyChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_peranswered"){
        initAnsweredChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
        // initAnsweredChart(result_message[0].data[0].value,result_message[0].data[0].percentage);
      }
      else if(result_message[0].data[0].type=="awb5_lngwaitingtime"){
        // initLongChart(result_message[0].data[0].value,100);
        initLongChart(result_message[0].data[0].value,100);
      }




      else if(result_message[0].data[0].status=="oncall"){        
                if(result_message[0].data[0].Type=="end"){
                      $('#removeoncall').val(result_message[0].data[0].agent_no); 
                      $('#removeoncall').click();
                }
                else{
                      $('#onCallData').val(event.data); 
                      $('#onCallData').click();}
      } 
      else if(result_message[0].data[0].status=="available"){
                if(result_message[0].data[0].Type=="end"){
                  $('#removeavailable').val(result_message[0].data[0].agent_no); 
                  $('#removeavailable').click();
            }else{
                $('#availData').val(event.data); 
                $('#availData').click();}
      } 
      else if(result_message[0].data[0].status=="makebusy"){
                if(result_message[0].data[0].Type=="end"){
                  $('#removemakebusy').val(result_message[0].data[0].agent_no); 
                  $('#removemakebusy').click();
            }else{
                $('#makebusyData').val(event.data); 
                $('#makebusyData').click();}
      } 
       else if(result_message[0].data[0].status=="outgoing"){
                if(result_message[0].data[0].Type=="end"){
                  $('#removeoutgoing').val(result_message[0].data[0].agent_no); 
                  $('#removeoutgoing').click();
            }else{
                $('#outgoingData').val(event.data); 
                $('#outgoingData').click();}
      }
      else if(result_message[0].data[0].status=="logout"){
                  if(result_message[0].data[0].Type=="end"){
                    $('#removelogout').val(result_message[0].data[0].agent_no); 
                    $('#removelogout').click();
              }else{
                        $('#logoutData').val(event.data); 
                        $('#logoutData').click();}
            }
      else if(result_message[0].data[0].ano!=undefined){
        $('#agentsData').val(event.data); 
        $('#agentsData').click();
      }
      else if(result_message[0].data[0].qno!=undefined){
        $('#queuesData').val(event.data); 
        $('#queuesData').click();
      }
      else if(result_message[0].data[0].queue_name!=undefined){
        $('#ques_list').val(event.data); 
        $('#ques_list').click();
      }
      else if(result_message[0].data[0].agent_name!=undefined){
        $('#agent_list').val(event.data); 
        $('#agent_list').click();
      } 
      else if(result_message[0].data[0].single_queue!=undefined){
        $('#single_queue').val(event.data); 
        $('#single_queue').click();
      } 
      else if( result_message[0].data[0].single_agent!=undefined){
        $('#single_agent').val(event.data); 
        $('#single_agent').click();
      } 
      else if( result_message[0].data[0].logged_queues!=undefined){
        $('#logged_queues').val(event.data); 
        $('#logged_queues').click();
      }
     else {
      
    }  
    
  }
  this.websocket.onerror = function(event){
    console.log('error');
  }
  this.websocket.onclose = function(event){
    console.log('close');
  } 

  
  
}

ngOnDestroy(): void {
  //Called once, before the instance is destroyed.
  //Add 'implements OnDestroy' to the class.
 
}
deleteRow(qry,data){
         var r_id= $('#'+qry).val();        
  for(let i = 0; i < data.length; ++i){
      if (data[i].agent_no === r_id) {
            data.splice(i,1);
      }
  }
}
// deleteRow(d){
//   alert(d);  
//   const index = this.onCall.indexOf(d);
  
//   this.onCall.splice(index, 1);
// }

sendOnload(){
  // var socket_message  =  '[{"Name":"onload"}]';
  var socket_message  = '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"onload","Type":"advance_wallboard_5"}]}]' ;
  this.websocket.send(socket_message);
}

onCallData(){
  let socketData = $('#onCallData').val();
  let mData = JSON.parse(socketData);
  this.onCall = mData[0].data;
  console.log(this.onCall);
}

availData(){
  let socketData = $('#availData').val();
  let mData = JSON.parse(socketData);
  this.availDatas = mData[0].data;
  console.log(this.availDatas);
}
makebusyData(){
  let socketData = $('#makebusyData').val();
  let mData = JSON.parse(socketData);
  this.makebusyDatas = mData[0].data;
  console.log(this.makebusyDatas);
}
outgoingData(){
  let socketData = $('#outgoingData').val();
  let mData = JSON.parse(socketData);
  this.outgoingDatas = mData[0].data;
  console.log(this.outgoingDatas);
}
logoutData(){
  let socketData = $('#logoutData').val();
  let mData = JSON.parse(socketData);
  this.logoutDatas = mData[0].data;
  console.log(this.logoutDatas);
}



agentsData(){
  let socketData = $('#agentsData').val();
  let mData = JSON.parse(socketData);
  this.agentsDatas = mData[0].data;
  console.log(this.agentsDatas);
}

queuesData(){
  let socketData = $('#queuesData').val();
  let mData = JSON.parse(socketData);
  this.queuesDatas = mData[0].data;
  console.log(this.queuesDatas);
}

checkLicenseKey(){
  let access_token: any=localStorage.getItem('access_token');

  let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"check_hardware","user_id":"'+this.loginUser+'"}}';
  this.serverService.sendServer(api_req).subscribe((response:any) => {
    if(response.result.data.value=='1'){
      this.initSocket();            
    } else {
      iziToast.error({
        message: "Your Licence Key is expired!.. please enter your key or contact admin",
        position: 'topRight'
        });
        $("#addLicence").modal({"backdrop": "static"});
        this.show_act_wall = true;
    }
  }, 
  (error)=>{
      console.log(error);
  });
}




single_queue(){
  let socketData = $('#single_queue').val(); 
  let mData = JSON.parse(socketData);
  var single_queue = mData[0].data;

  var qname = single_queue[0].single_queue+'_queue_name';
  var callwaiting= single_queue[0].single_queue+'_callwaiting';
  var lng_callwaiting = single_queue[0].single_queue+'_lng_callwaiting';
  var totalcall = single_queue[0].single_queue+'_totalcall';
  var answerd = single_queue[0].single_queue+'_answerd';
  var missed = single_queue[0].single_queue+'_missed';
  var sla_per = single_queue[0].single_queue+'_sla_per';

  

  $('#'+qname).html(single_queue[0].single_queue_name);
  $('#'+callwaiting).html(single_queue[0].callwaiting);
  $('#'+lng_callwaiting).html(single_queue[0].lng_callwaiting);
  $('#'+totalcall).html(single_queue[0].totalcall);
  $('#'+answerd).html(single_queue[0].answerd);
  $('#'+missed).html(single_queue[0].missed);
  // $('#'+sla_per).html(single_queue[0].sla_per);

  $('#'+sla_per).html('<span>'+single_queue[0].sla_per+'%</span><div class="progress progress-thin"><div class="progress-bar progress-bar-green" style="width: '+single_queue[0].sla_per+'%;"  ></div></div>');

 
}



single_agent(){
  let socketData = $('#single_agent').val(); 
  let mData = JSON.parse(socketData);
  var single_agent = mData[0].data;
  console.log(single_agent);
  console.log(single_agent[0].single_agentno);

  var aname = single_agent[0].single_agentno+'_agentname';
  var avg_talking= single_agent[0].single_agentno+'_avg_talking';
  var avgoutbound = single_agent[0].single_agentno+'_avgoutbound';
  var missed = single_agent[0].single_agentno+'_missed';
  var answerd = single_agent[0].single_agentno+'_answerd';
  var state = single_agent[0].single_agentno+'_state';
  var statetime = single_agent[0].single_agentno+'_statetime';
  var totoutbound = single_agent[0].single_agentno+'_totoutbound';
  

  $('#'+aname).html(single_agent[0].single_agent);
  $('#'+avg_talking).html(single_agent[0].avg_talking);
  // $('#'+avgoutbound).html(single_agent[0].avgoutbound);
  $('#'+missed).html(single_agent[0].missed);
  // $('#'+answerd).html(single_agent[0].answerd);
  $('#'+state).html('<span>'+single_agent[0].state+'</span>');
  $('#'+statetime).html(single_agent[0].statetime);
  $('#'+totoutbound).html(single_agent[0].totoutbound);
  
  

  $('#'+avgoutbound).html('<span>'+single_agent[0].avgoutbound+'%</span><div class="progress progress-thin"><div class="progress-bar progress-bar-green" style="width: '+single_agent[0].avgoutbound+'%;"  ></div></div>');

 $('#'+answerd).html('<span>'+single_agent[0].answerd+'</span>');


}




addQueue(){
  $('#addQueue').modal('show');
}



changeData(){
  let socketData = $('#ques_list').val(); 
  let mData = JSON.parse(socketData);
  this.queueData = mData[0].data;
}

getQueues(){
  var mainQueues = $('.queues_check:checked').map(function(){
    return this.value;
}).get();


if(mainQueues == ''){

  iziToast.warning({
    message: "Please Select Atleast One",
    position: 'topRight'
});
  return false;
}
this.has_hard_id = localStorage.getItem('hardware_id');
 mainQueues = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainQueues.join()+']}]';
//  alert(mainQueues);
 console.log(mainQueues);
 this.websocket.send(mainQueues);
 $('#addQueue').modal('hide');
}

addUsers(){
  $('#addUsers').modal('show');
}

changeAgents(){
  let socketData = $('#agent_list').val(); 
  let mData = JSON.parse(socketData);
  this.addagentsData = mData[0].data;
}

getQueUsers(){
  var mainAgents = $('.agents_check:checked').map(function(){
    return this.value;
}).get();


if(mainAgents == ''){

  iziToast.warning({
    message: "Please Select Atleast One",
    position: 'topRight'
});
  return false;
}
this.has_hard_id = localStorage.getItem('hardware_id');

mainAgents = '[{"cust_id":"'+this.has_hard_id+'","data":['+mainAgents.join()+']}]';
// alert(mainAgents);
 this.websocket.send(mainAgents);
 $('#addUsers').modal('hide');
}

 
slectAllQue(){
  if($("#selectAllQ").prop("checked")) {
    $(".queues_check").prop("checked", true);
} else {
    $(".queues_check").prop("checked", false);
} 
}

slectunique(){
	$("#selectAllQ").prop("checked",false)
  }

slectAllagent(){
  if($("#slectAllagent").prop("checked")) {
    $(".agents_check").prop("checked", true);
} else {
    $(".agents_check").prop("checked", false);
} 
}
slectuniqueagent(){
	$("#slectAllagent").prop("checked",false)
  }


  manageCallQueue(){
    $('#manageCallQueue').modal('show');
  }
  

  loggedQueues(){
    let socketData = $('#logged_queues').val(); 
    let mData = JSON.parse(socketData);
    this.logged_queues = mData[0].data;
  }

}

