import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from '../services/server.service';
declare var $:any;
declare var iziToast:any;
import * as moment from 'moment';
 
@Component({
  selector: 'app-broadcast-report',
  templateUrl: './broadcast-report.component.html',
  styleUrls: ['./broadcast-report.component.css']
})
export class BroadcastReportComponent implements OnInit {
  res;
  getRep: FormGroup;
  usersL;
  selected_price : any;
  userData = {"licensce_key": ""};
  public isKeyAvail = false;
  public isItemAvailable = false;
  s_licence_key : any;
  items: any;
  accessToken:any;
  user_id:any;
  admin_id;callers;
  recordNotFound = false;
  offset_count = 0;call_history_list;tot_data;total_name;percent;
  price_sms;
  bd_name;
  constructor(private serverService: ServerService, private router:Router) { }

  ngOnInit(): void {
    this.getRep = new FormGroup({
      'from' :new FormControl(null),
      'to' :new FormControl(null)
    });
    this.bd_names();
  }
  bd_names(){
   
    let access_token: any=localStorage.getItem('access_token');
   
    let admin_id=localStorage.getItem('admin_id');
   
    let api_req:any = '{"operation":"bd_report", "moduleType":"bd_report", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"bd_names","admin_id":"'+admin_id+'"}}';
  
                  this.serverService.sendServer(api_req).subscribe((response:any) => {
                  
                      if(response.status == true){
                  
                        this.bd_name=response.result.data;
                        
                      }else {
                        this.recordNotFound = true;
                      }
                  }, 
                  (error)=>{
                      console.log(error);
                  });

  }
  searchData(){
    let name=$('#camp_name').val().join();
  var type = $('#type').val().join();
  // alert(type);

    let access_token: any=localStorage.getItem('access_token');
    let agent_req:any = this.getRep.value;

    var starts =moment(this.getRep.value.from).format('YYYY-MM-DD HH:mm:ss');
 var ends =moment(this.getRep.value.to).format('YYYY-MM-DD HH:mm:ss');
 
    let api_req:any = new Object();
    agent_req.action="bd_report";
    agent_req.admin_id = localStorage.getItem('admin_id');
    agent_req.from = starts;
    agent_req.to = ends;
    agent_req.type = type;
    // report_details.admin_id = '64';
    agent_req.name =name;
    api_req.operation="bd_report";
    api_req.moduleType="bd_report";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = agent_req;
          this.serverService.sendServer(api_req).subscribe((response:any) => {
            if(response.status==true){
              this.call_history_list = response.result.data;
            } 
 
             
          }, 
          (error)=>{
              console.log(error);
          });

  }



  genReport(){
    let report_details:any = this.getRep.value;



    let api_req:any = new Object();
    report_details.action="bd_report";
    report_details.admin_id = localStorage.getItem('admin_id');
    api_req.operation="bd_report";
    api_req.moduleType="bd_report";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = report_details;
    //console.log(api_req); return false;

    this.serverService.sendServer(api_req).subscribe((response:any) => {
      console.log(response);
      if(response.result.status==true){
      this.res = response;
      var arrStr = encodeURIComponent(JSON.stringify(this.res));
      // document.location.href = 'https://managedservices.mconnectapps.com/api/storage/contact/download.php?res='+arrStr;
      var url = 'https://managedservices.mconnectapps.com/api/storage/call/Broadcast_report.php';
      var form = $('<form action="' + url + '" method="post">' +
      '<input type="text" name="res" value="' + arrStr + '" />' +
      '</form>');
      $('body').append(form);
      form.submit();
    
    } else {
      iziToast.warning({
      message: "No Records Found. Please try again",
      position: 'topRight'
    });
    }
    }, 
    (error)=>{
        console.log(error);
    });
  }



}

