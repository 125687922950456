import { Component, OnInit } from '@angular/core';
import { ServerService } from '../../services/server.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HostListener } from '@angular/core';
import Swal from 'sweetalert2'
declare var $:any;
declare var call: any;
declare var transferCall: any;
declare var init_page: any;
declare var getinStatus: any;
declare var iziToast:any;
@Component({
  selector: 'app-dialpad',
  templateUrl: './dialpad.component.html',
  styleUrls: ['./dialpad.component.css'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event)'
  }
})

export class DialpadComponent implements OnInit {
    dialPadContainer;
    quelogged;
    inc_or_out;
    callBalance;
    queLogStatus;
	dialPadCirclePlus;
	dialPadRefresh;
	dialPadActionview;
	totalSeconds;
 	callduration_timer;
 	dialpadRecentCalls;
 	dialpadOutgoingCalls;
 	dialpadIncomingCalls;
 	dialpadIncomingCallsProgress;
 	dialpadUserList;
     callDetailView;
     admincallBalance;
    userDetailView;
    sip_login;
    sip_authentication;
    sip_password;
    sip_port;
    sip_url;
    call_history_id;
    uadmin_id;
    key;
    dial_status;
    auxcodesM;
    redyForCall;
    loginUser;
    websocket;
    extension;
    has_hard_id;
    in_current_call = '';
    forwordPopup ='notforword';
    public isVisible: boolean = false;
    show_caller_id;
    survey_vid;
    allmyQues;
    ext_num;
  	constructor(private serverService: ServerService,private router:Router) {

       this.serverService.show.subscribe( (val:any) => 
       {
       
        var dpContent = JSON.parse(val);
        if(dpContent.type == "makecall"){
           var caller_if = dpContent.show_caller_id;
            this.webMakeCall(dpContent.number,caller_if);
        } else if(dpContent.type == "makecallauto"){
            this.makecallauto(dpContent.number)
        }
        
        if(dpContent.type == "queLoginOut"){

                let api_reqs:any = '{"type": "queLoginOut","status":"'+this.queLogStatus+'"}';
                this.serverService.qLogin.next(api_reqs);

        //this.q_logout(dpContent.status);
        }
       });


    }

  ngOnInit() {
	this.dialPadContainer=false;
	this.dialPadCirclePlus=true;
	this.dialPadRefresh=false;
    this.dialPadActionview = "number_dailer";
    this.pbxSettings();

    $("#getallmyqueue").prop("checked", false);
    this.loginUser = localStorage.getItem('userId');

    this.ext_num=  localStorage.getItem('ext_num');
    $('#user_number').val(this.ext_num);
    console.log($('#user_number').val());
    this.has_hard_id = localStorage.getItem('hardware_id');
    if(this.has_hard_id == ""){
        // $('#addLicence').modal('show');
        //$("#addLicence").modal({"backdrop": "static"});
    } else {


        
        // if(this.loginUser == '64'){
        //     this.websocket = new WebSocket("wss://socket.mconnectapps.com:5013/"); 
        //   } else if(this.loginUser == '164'){
        //     this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
        //   } else if(this.loginUser == '201'){
        //     this.websocket = new WebSocket("wss://socket.mconnectapps.com:5014/"); 
        //   } else {
        //     this.websocket = new WebSocket("wss://socket.mconnectapps.com:5012/"); 
        //   }


        if(this.loginUser == '64'){
            this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4002"); 
          } else if(this.loginUser == '201'){
            this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4003"); 
          } else {
            this.websocket = new WebSocket("wss://myscoket.mconnectapps.com:4001"); 
          }


        this.websocket.onopen = function(event) { 
          console.log('Dialpad socket connected');
        }
    
        this.websocket.onmessage = function(event) {      
      
        var result_message = JSON.parse(event.data);
        console.log(result_message);  
        console.log($('#user_number').val());
         if(result_message[0].data[0].type =='callend' && result_message[0].data[0].userno == $('#user_number').val()){            
            iziToast.error({
                message: ""+result_message[0].data[0].value+"",
                position: 'topRight'
            }); 
          }
        }
    
        this.websocket.onerror = function(event){
          console.log('error');
        }
        this.websocket.onclose = function(event){
          console.log('close');
        } 
    }




  }
  ngAfterViewInit() {
}




handleKeyboardEvent(event: KeyboardEvent){
  this.key = event.key;
  if(this.key =='0'){ $('#zero').click(); }
  if(this.key =='1'){ $('#one').click(); }
  if(this.key =='2'){ $('#two').click(); }
  if(this.key =='3'){ $('#three').click(); }
  if(this.key =='4'){ $('#four').click(); }
  if(this.key =='5'){ $('#five').click(); }
  if(this.key =='6'){ $('#six').click(); }
  if(this.key =='7'){ $('#seven').click(); }
  if(this.key =='8'){ $('#eight').click(); }
  if(this.key =='9'){ $('#nine').click(); }
  if(this.key =='*'){ $('#star').click(); }
  if(this.key =='#'){ $('#hash').click(); }
  if(this.key =='Enter'){ 
    if(this.forwordPopup == 'forward'){
       this.makecallTransfer();
    } else if(this.dialPadActionview == 'outgoing_call_inprogess'){
        this.outgoingCallEnd();
    } else if(this.dialPadActionview == 'call_incoming') {
        this.incomingCallAccept();
    }  else if(this.dialPadActionview == 'incoming_call_inprogess') {
        this.incomingCallEnd();
    } else if(this.dialPadActionview == 'number_dailer')  {
        $('#call').click();
    }
}

 if(this.key == 'Backspace'){ this.dialPadbackSpace(); }


}
pbxSettings(){
// let api_req:any = new Object();
// let chat_req:any = new Object();
//  chat_req.action = "get_pbx_settings";
let access_token: any=localStorage.getItem('access_token');
this.uadmin_id = localStorage.getItem('userId');

let chat_req:any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_pbx_settingss","user_id":"'+this.uadmin_id+'"}}';
 
 this.serverService.sendServer(chat_req).subscribe((response:any) => {
     if(response.result.status==true){



        this.sip_login =atob(response.result.data.sip_login);        
        this.sip_authentication=atob(response.result.data.sip_authentication);
        this.sip_password=atob(response.result.data.sip_password);
        this.sip_port=atob(response.result.data.sip_port);
        this.sip_url=atob(response.result.data.sip_url);
        this.survey_vid =atob(response.result.data.sip_survey_vid);
     

            if(response.result.data == false){
                // $('.circle-plus').hide();
                this.dial_status = 'external';
                return false;
            } else {
                init_page(this.sip_login,this.sip_authentication,this.sip_password,this.sip_port,this.sip_url,'');
             
                setTimeout( () => { this.registerStatus(); }, 30000 );
            }


      
     
     }
 }, 
 (error)=>{
     console.log(error);
 });

}


getStatus(){
    let status: any = getinStatus();
    console.log(status);
    return status;
    
}

registerStatus(){
    this.dial_status = this.getStatus();

    if(this.dial_status == 'REGISTERED'){
        this.sipRegistration('1');
    } else if(this.dial_status == 'ESTABLISHED'){
        this.sipRegistration('2');
        setTimeout( () => { this.registerStatus2(); }, 30000 );
    } else {
        this.sipRegistration('0');
        setTimeout( () => { this.registerStatus2(); }, 30000 );
    }
}

registerStatus2(){
    this.dial_status = this.getStatus();

    if(this.dial_status == 'REGISTERED'){
        this.sipRegistration('1');
    } else if(this.dial_status == 'ESTABLISHED'){
        this.sipRegistration('2');
    } else {
        this.sipRegistration('0');
    }
}
dialPadOpen() {
    
    if(this.dial_status === 'external'){
        iziToast.warning({
            message: "Sorry, Please Change the dialer settings or give the valid pbx details",
            position: 'topRight'
        });
        return false;
    } else {
        this.dial_status = this.getStatus();

        if(this.dial_status == 'REGISTERED'){
            this.sipRegistration('1');
        } else if(this.dial_status == 'ESTABLISHED'){
            this.sipRegistration('2');
        } else {
            this.sipRegistration('0');
        }

        if(this.dial_status == 'NULL'){
            iziToast.warning({
                message: "Please Wait Dialpad is Loading",
                position: 'topRight'
            });
            return false;
        } 
    }
 
    


    let access_token: any=localStorage.getItem('access_token');
	let que: any =  $('#que').val();  
    let api_req:any = '{"operation":"call", "moduleType":"call", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"queue_login_logout","user_id":"'+this.uadmin_id+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.data.status== "1"){
        this.dialPadContainer = true;
        this.dialPadCirclePlus = false;
        this.queLogStatus = response.result.data.status;
        this.redyForCall = 'Ready for calls';
      } else {
        this.queLogStatus = response.result.data.status;
        this.redyForCall = response.result.data.reason;
        this.dialPadContainer = true;
        this.dialPadCirclePlus = false;
        $('#onHookIndi').addClass('red')
      }
    }, 
    (error)=>{
        console.log(error);
    });

   
}




dialPadClose() {

    this.dialPadContainer = false;
    this.dialPadCirclePlus = true;

    $("#dialpad-wrapper").removeClass("enable-fullscreen-dialpad");
$(".card").removeClass("none");
$(".main-sidebar, .main-footer, .navbar, .card, .main-content").removeClass("blur");

}
keyPad(key_data) {
if(this.forwordPopup == 'forward'){
    var dailed_number = $('#makeCallForwordNumber').val();
    $('#makeCallForwordNumber').val(dailed_number + key_data);
} else {
    var dailed_number = $('#dialpad_number').val();
    $('#dialpad_number').val(dailed_number + key_data);
}
    

}

recentCallSearch(data) {

    var search_txt = data.target.value.toLowerCase();

    $("#recentCalls .contact-list-item").filter(function () {
        $("#recentCalls .contact-list-item").toggle($("#recentCalls .contact-list-item").text().toLowerCase().indexOf(search_txt.toLowerCase()) !== -1);
    });

}

userListSearch(data) {

    var search_txt = data.target.value.toLowerCase();

    $("#userList .contact-list-item").filter(function () {

        $(this).toggle($(this).text().toLowerCase().indexOf(search_txt.toLowerCase()) !== -1);
    });

}

dialPadview(view_type) {

    if (view_type != "number_dailer") {

        let api_req: any = new Object();
        let dialpad_req: any = new Object();
        dialpad_req.user_id = localStorage.getItem('userId');
        dialpad_req.action = view_type;
        if (view_type == "recent_list") {
            dialpad_req.search_text = "";
            dialpad_req.order_by_name ="history.callid" ;
            dialpad_req.order_by_type = "desc";
            dialpad_req.limit = 50;
            dialpad_req.offset = 0;
        }

        api_req.operation = "call";
        api_req.moduleType = "call";
        api_req.api_type = "web";
        api_req.access_token = localStorage.getItem('access_token');
        api_req.element_data = dialpad_req;

        this.serverService.sendServer(api_req).subscribe((response: any) => {

            if (response.result.status == 1) {
                if (view_type == "recent_list") {
                    this.dialpadRecentCalls = response.result.data.list_data;
                } else if (view_type == "user_list") {
                    this.dialpadUserList = response.result.data;
                }

                this.dialPadActionview = view_type;
            }
        }, (error) => {
            console.log(error);
        });
    } else {
        this.dialPadActionview = "number_dailer";
    }

}



dialPadbackSpace() {
    if(this.forwordPopup == 'forward'){
        var dialpad_number = $('#makeCallForwordNumber').val();
        if(dialpad_number == undefined){
        } else {
            $('#makeCallForwordNumber').val(dialpad_number.substring(0, dialpad_number.length - 1));
        }
     } else {
        var dialpad_number = $('#dialpad_number').val();
        if(dialpad_number == undefined){
        } else {
            $('#dialpad_number').val(dialpad_number.substring(0, dialpad_number.length - 1));
        }
     }   
}


dialCall() {

    var dialpad_number = $('#dialpad_number').val();

        if(this.in_current_call == 'incoming_call_inprogess'){
           console.log('outgoing_call_inprogess');
           var number_data = this.clean_number(dialpad_number);
           this.dialPadDetailView('outgoing_call_inprogess', number_data);
           $('#dialpad_number').val('');
           transferCall(dialpad_number); 
           this.in_current_call = '';
        } else {
            if (dialpad_number.length > 2) {
                this.webMakeCall(dialpad_number,'1');
            }
        }



    
}


webMakeCall(number_key,show_caller_id) {
    this.show_caller_id = show_caller_id;
    var number_data = this.clean_number(number_key);
    if (!$('#dialpad-refresh').length) {
        this.dialPadOpen();
    }

    if (number_data.length > 2) {

        this.inc_or_out = number_data;
        $('#make_call_number').val(number_data);
        this.dialPadDetailView('outgoing_call_inprogess', number_data);
        call();
        $('#dialpad_number').val('');
        
        // let hardware_id: any=localStorage.getItem('hardware_id');
        // let access_token: any=localStorage.getItem('access_token');
        // let admin_id: any=localStorage.getItem('admin_id');
        // let api_req:any = '{"operation":"call_tarrif", "moduleType":"call_tarrif", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_call_balance","agent_id":"'+this.uadmin_id+'","admin_id":"'+admin_id+'","from_no":"'+this.sip_login+'","to_no":"'+number_data+'","hardware_id":"'+hardware_id+'"}}';
        // this.serverService.sendServer(api_req).subscribe((response:any) => {
        //     if(response.result.status == true){
        //         var data = response.result.data;


        //         // this.callBalance = 4;
        //         // this.admincallBalance = 3;
        //         // $('#make_call_number').val(number_data);
        //         // this.dialPadDetailView('outgoing_call_inprogess', number_data);
        //         // call();
        //         // $('#dialpad_number').val('');

        //         if(data.balance == "" || data.balance == false ){
        //             iziToast.error({
        //                 message: "Sorry, Insufficient Balance",
        //                 position: 'topRight'
        //             });
        //         } else {
        //             this.admincallBalance = data.balance;
        //             this.callBalance = data.balance;
        //             // $('#make_call_number').val(number_data);
        //             // this.dialPadDetailView('outgoing_call_inprogess', number_data);
        //             // call();
        //             // $('#dialpad_number').val('');
        //         }
        //     }
        // }, 
        // (error)=>{
        //     console.log(error);
        // }); 


       
    }
}
outGongCallstatus(){
    this.admincallBalance = this.admincallBalance * 1000;
    setTimeout(function(){ 
        var outGongCallstatus = $('#outGongCallstatus').val();
        if(outGongCallstatus == 'inCall'){
            iziToast.error({
                        message: "Sorry, Admin Has Insufficient Balance. Please Contact Support",
                        position: 'topRight'
                    });
                    this.outgoingCallEnd()
        }

    }, this.admincallBalance);
}

countdownTim(){
    //alert(this.callBalance);
    this.callBalance = this.callBalance * 1000;
    setTimeout(function(){ 
        var CallToCut = $('#outGongCallstatus').val();
        if(CallToCut == 'inCall'){
            iziToast.error({
                        message: "Sorry, Insufficient Balance. Please Contact Support",
                        position: 'topRight'
                    });
                    this.outgoingCallEnd()
        }
    
    }, this.callBalance);
}


outgoingCallEnd() {
    $("#makecallHanupBtn").click();
    this.dialPadDetailView('call_history_detail', this.call_history_id);
    $('#animate-dialpad').modal('hide');
    $(".forwardDialpadPanel").addClass('hide-fwd-dialpad');
    $(".forwardDialpadPanel").hide();
    this.forwordPopup = 'outgoingCallEnd';
    $('#makeCallForwordNumber').val('');
}

incomingCallAccept() {
    $("#incomingCallAnswerBtn").click();
    this.dialPadDetailView('incoming_call_inprogess', this.call_history_id);
}


incomingCallDecline() {

    $("#incomingCallHangupBtn").click();
    this.dialPadDetailView('call_history_detail', this.call_history_id);
}

incomingCallEndByCustomer() {
    this.dialPadDetailView('call_history_detail', this.call_history_id);

}

incomingCallEnd() {


    $("#incomingCallHangupBtn").click();
    this.dialPadDetailView('call_history_detail', this.call_history_id);
    $('#animate-dialpad').modal('hide');
    $(".forwardDialpadPanel").addClass('hide-fwd-dialpad');
    $(".forwardDialpadPanel").hide();
    this.forwordPopup = 'incCallEnd';
    $('#makeCallForwordNumber').val('');
    $('#dialpad_number').val('');
}


clean_number(number_key) {
    var number_data = number_key.trim();
    return number_data.replace(/[\s-/.\u00AD]/g, '');
}

incoming_call_trigger() {
    var call_incoming_number = $('#call_incoming_number').val();
    this.inc_or_out = call_incoming_number;
    if (call_incoming_number != "") {
        this.dialPadOpen();
        this.dialPadDetailView('call_incoming', call_incoming_number);
        
    }
}

dialPadDetailView(view_type, detail_id) {

    if (detail_id == '' || detail_id == undefined) {

        detail_id = null;
    }
       

    if (view_type != "number_dailer") { 

        let api_req: any = new Object();
        let dialpad_req: any = new Object();
        dialpad_req.user_id = localStorage.getItem('userId');
        dialpad_req.action = view_type;
        if (view_type == "call_history_detail") {
            dialpad_req.callid = detail_id;
            $('#animate-dialpad').modal('hide');
            $(".forwardDialpadPanel").addClass('hide-fwd-dialpad');
            $(".forwardDialpadPanel").removeClass('active');
            $(".forwardDialpadPanel").hide();
            $('#makeCallForwordNumber').val('');
            
            this.forwordPopup = 'forwarded';
        } else if (view_type == "user_detail_view") {
            dialpad_req.user_id = detail_id;
        } else if (view_type == "outgoing_call_inprogess") {
           // this.inc_or_out = detail_id;
            dialpad_req.call_data = "Call to " + detail_id;
            dialpad_req.customer_id = 0;
            dialpad_req.call_type = "outgoing";
            dialpad_req.phone = detail_id;
            dialpad_req.call_status = "answered";
            dialpad_req.call_note = "";
           // alert('answered')

            
        } else if (view_type == "call_incoming") {
            dialpad_req.call_data = "Call from " + detail_id;
            dialpad_req.customer_id = 0;
            dialpad_req.call_type = "incoming";
            dialpad_req.phone = detail_id;
            dialpad_req.call_status = "open";
            dialpad_req.call_note = "";
        } else if (view_type == "incoming_call_inprogess") {
            dialpad_req.callid = detail_id;
            this.in_current_call = view_type;
        }
        api_req.operation = "call";
        api_req.moduleType = "call";
        api_req.api_type = "web";
        api_req.access_token = localStorage.getItem('access_token');
        api_req.element_data = dialpad_req;

        this.serverService.sendServer(api_req).subscribe((response: any) => {

            if (response.result.status == 1) {

              //  this.inc_or_out  =  response.result.data.phone;

                if (view_type == "call_history_detail") {
                    this.callDetailView = response.result.data;
                    
                } else if (view_type == "user_detail_view") {
                    this.userDetailView = response.result.data;
                } else if (view_type == "outgoing_call_inprogess") {
                    this.dialpadOutgoingCalls = dialpad_req;
                    $('#call_history_id').val(response.result.data);
                    this.call_history_id = response.result.data;
                    detail_id = btoa(detail_id); // Base64 encode the String
                    this.router.navigate(['/edit-contacts'], { queryParams: { phone: detail_id , call_rec_id: this.call_history_id} });

                } else if (view_type == "call_incoming") {
                    this.dialpadIncomingCalls = dialpad_req;
                    $('#call_history_id').val(response.result.data);
                    this.call_history_id = response.result.data;
                    detail_id = btoa(detail_id); // Base64 encode the String
                    var predective_dialer_behave = localStorage.getItem('predective_dialer_behave');
                    if(predective_dialer_behave == '0')
                    {
                        var has_external_contact = localStorage.getItem('has_external_contact');

                        if(has_external_contact == '0') {
                            this.router.navigate(['/edit-contacts'], { queryParams: { phone: detail_id , call_rec_id: this.call_history_id} });
                        } else {
                            var crm_type = localStorage.getItem('crm_type');
                            if(crm_type == 'HubSpot'){
                            var ext_url = localStorage.getItem('external_contact_url');
                            let searchParams = new URLSearchParams(ext_url)
                            let h_authkey = searchParams.get('hapikey');
                            var decodedString = atob(detail_id );
                             this.router.navigate(['/edit-contacts'], { queryParams: { q: decodedString , hapikey: h_authkey} });                   
                           } else if(crm_type == 'ZohoDesk'){
                            var ad = localStorage.getItem('admin_id');

                            var ext_url = localStorage.getItem('external_contact_url');
                            let searchParams = new URLSearchParams(ext_url)
                            let Z_orgId = searchParams.get('orgId');
                            let z_authkey = searchParams.get('authkey');

                            var decodedString = atob(detail_id );
                            this.router.navigate(['/edit-contacts'], { queryParams: { q: decodedString ,authkey: z_authkey,orgId: Z_orgId } });                
                           }else if(crm_type == 'SalesForce'){
                            this.router.navigate(['/edit-contacts'], { queryParams: { q: 'locale=in' } });                   
                          } else {
    
                           }
                        }
                       

                    } else {
                        

                        var has_external_contact = localStorage.getItem('has_external_contact');

                        if(has_external_contact == '0') {
                            this.router.navigate(['/predictive-dialer-calls'], { queryParams: { phone: detail_id , call_rec_id: this.call_history_id} });
                        } else {
                            var crm_type = localStorage.getItem('crm_type');
                            if(crm_type == 'HubSpot'){
                                var decodedString = atob(detail_id );
                             this.router.navigate(['/edit-contacts'], { queryParams: { q: decodedString , hapikey: 'ed15f3fa-87c4-4169-a555-6bb845c257e9'} });                   
                           } else if(crm_type == 'ZohoDesk'){
                               var ad = localStorage.getItem('admin_id');
                               var ext_url = localStorage.getItem('external_contact_url');
                               let searchParams = new URLSearchParams(ext_url)
                               let Z_orgId = searchParams.get('orgId');
                               let z_authkey = searchParams.get('authkey');
   
                               var decodedString = atob(detail_id );
                               this.router.navigate(['/edit-contacts'], { queryParams: { q: decodedString ,authkey: z_authkey,orgId: Z_orgId } });    
                                           
                           }else if(crm_type == 'SalesForce'){
                            this.router.navigate(['/edit-contacts'], { queryParams: { q: 'locale=in' } });                   
                          } else {
    
                           }
                        }



                    }
                }
                this.dialPadActionview = view_type;
            }
        }, (error) => {
            console.log(error);
        });
    }

}



sendOnload(status,reason,queues){
    var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"queuestatus","extension":"'+this.extension+'","status":"'+status+'","reason":"'+reason+'","queues":"'+queues+'"}]}]';
    this.websocket.send(socket_message);
}




q_logout(queu){
    this.myProfile();
    if(queu == 1){
        Swal.fire({
            title: 'Confirm Queue Logout',
            text: "This action will log you OUT of all queues and queue calls will not be sent to your extension until you log back in",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Not now',
            confirmButtonText: 'Yes'
          }).then((result) => {
            if (result.value) {
                this.aux_codeDatas();
                this.myqueues();
            }
          })

    } else {
        this.loginQ()  
    }
    
}


myqueues(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('admin_id');
    let api_req:any = '{"operation":"queue", "moduleType":"queue", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"user_queue","agent_id":"'+this.uadmin_id+'","admin_id":"'+admin_id+'"}}';
    this.serverService.sendServer(api_req).subscribe((response:any) => {
        if(response.result.status == true){
                this.allmyQues = response.result.data;
                $('#Qlogform').modal('show');
        }
    }, 
    (error)=>{
        console.log(error);
    });   
}




updateQ(){
    let access_token: any=localStorage.getItem('access_token');
    var queues = $('.getallmyqueues:checked').map(function(){
        return this.value;
    }).get();



    if(queues ==''|| queues=='0' ){
        iziToast.warning({
            message: "Please Select atleast one Queue",
            position: 'topRight'
        }); 
        return false;
    }

    let que: any =  $('#que').val();  

    if(que ==''|| que=='0' ){
        iziToast.warning({
            message: "Please Select Auxcode",
            position: 'topRight'
        }); 
        return false;
    }


    let api_req:any = '{"operation":"call", "moduleType":"call", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"in_login_logout","agent_id":"'+this.uadmin_id+'","reason":"'+que+'","status":"0"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {

        let api_reqs:any = '{"type": "profile"}';
        this.serverService.profile.next(api_reqs);

        this.sendOnload('0',que,queues);
      if(response.result.status == true){

        
        iziToast.success({
            message: "Logout successfully",
            position: 'topRight'
        });
        $('#Qlogform').modal('hide');
        this.dialPadClose();
      } else {
        iziToast.error({
            message: "Sorry some error occured",
            position: 'topRight'
        });
        $('#Qlogform').modal('hide');
      }
    }, 
    (error)=>{
        console.log(error);
    });
}



// q_login(){
    
//     $('#Qloginform').modal('show');
// }



loginQ(){
    let access_token: any=localStorage.getItem('access_token');
	let que: any =  $('#que').val();  
    let api_req:any = '{"operation":"call", "moduleType":"call", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"in_login_logout","agent_id":"'+this.uadmin_id+'","reason":"","status":"1"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {

        let api_reqs:any = '{"type": "profile"}';
        this.serverService.profile.next(api_reqs);
        this.sendOnload('1',que,'');
        if(response.result.status == true){
            
        iziToast.success({
            message: "Login successfully!",
            position: 'topRight'
        });
        this.queLogStatus = '1';
        this.redyForCall = 'Ready for calls';
      } else {
        iziToast.error({
            message: "Sorry some error occured",
            position: 'topRight'
        });
      }
    }, 
    (error)=>{
        console.log(error);
    });
}






aux_codeDatas(){
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('admin_id');
    let api_req:any = '{"operation":"auxcode", "moduleType":"auxcode", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_auxcode","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
      if(response.result.status==true){
        this.auxcodesM = response.result.data;
      } 
    }, 
    (error)=>{
        console.log(error);
    });
  }

openFullscreen(){

$("#dialpad-wrapper").addClass("enable-fullscreen-dialpad");
$(".card").addClass("none");
$(".main-sidebar, .main-footer, .navbar, .card, .main-content").addClass("blur");

}








myProfile(){
    let api_req:any = new Object();
    let get_agent_req:any = new Object();
    get_agent_req.user_id=localStorage.getItem('userId');
    get_agent_req.action='get_agent_data';
    api_req.operation="agents";
    api_req.moduleType="agents";
    api_req.api_type="web";
    api_req.access_token=localStorage.getItem('access_token');
    api_req.element_data = get_agent_req;
        this.serverService.sendServer(api_req).subscribe((response: any) => {  
          if(response.result.status==true){
                this.extension = response.result.data.sip_login;
          }
           
        }, 
        (error)=>{
            console.log(error);
        });
  }


sipRegistration(status){
    let access_token: any=localStorage.getItem('access_token');
    let user_id: any=localStorage.getItem('userId');

    let api_req:any = '{"operation":"agents", "moduleType":"agents", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"sip_registered_status","user_id":"'+user_id +'","status":"'+status+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {
     
    }, 
    (error)=>{
        console.log(error);
    });
  }



  Callforword(){
    $(".forwardDialpadPanel").addClass('active');
    $(".forwardDialpadPanel").show();
    $(".forwardDialpadPanel").removeClass('hide-fwd-dialpad');
    this.forwordPopup = 'forward';
  }






  makecallTransfer(){
      var dialNumber = $('#makeCallForwordNumber').val();
      var number_data = this.clean_number(dialNumber);
      this.dialPadDetailView('outgoing_call_inprogess', number_data);
      $('#animate-dialpad').modal('hide');
        $(".forwardDialpadPanel").addClass('hide-fwd-dialpad');
         $(".forwardDialpadPanel").show();
      this.forwordPopup = 'forwarded';
      $('#makeCallForwordNumber').val('');
      $('#dialpad_number').val('');
      transferCall(dialNumber); 
      this.in_current_call = '';
  }

  checkallQueues(){
    if($('#getallmyqueue').is(':checked')){
        $(".getallmyqueues").each(function() {
            $("#checkedAll").prop("checked", true);
        });
        $(".getallmyqueues").prop("checked", true);
    } else {
        $(".getallmyqueues").each(function() {
            this.checked=false;
        });
        $(".getallmyqueues").prop("checked", false);
    }

}








transferCallToSurvay(){

    var ani = this.inc_or_out;
    var dins = this.sip_login;
    let access_token: any=localStorage.getItem('access_token');
    let admin_id: any=localStorage.getItem('admin_id');

    let api_req:any = '{"operation":"pre_camp", "moduleType":"pre_camp", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"insert_survey","posted_key":"","ani":"'+ani+'","dins":"'+dins+'","admin_id":"'+admin_id+'"}}';
  
    this.serverService.sendServer(api_req).subscribe((response:any) => {

        if(response.result.data==1){

            var admin_id = localStorage.getItem('admin_id');
            transferCall(this.survey_vid); 
      } else {
        iziToast.error({
            message: "Sorry some error occured",
            position: 'topRight'
        });
      }
     
    }, 
    (error)=>{
        console.log(error);
    });
  }




  makecallauto(tono){
    let access_token: any=localStorage.getItem('access_token');
    this.uadmin_id = localStorage.getItem('userId');
    let chat_req:any = '{"operation":"chat", "moduleType": "chat", "api_type": "web", "access_token":"'+access_token+'", "element_data":{"action":"get_pbx_settingss","user_id":"'+this.uadmin_id+'"}}';
     this.serverService.sendServer(chat_req).subscribe((response:any) => {
         if(response.result.status==true){
            this.sip_login =atob(response.result.data.sip_login);        
            var socket_message  =  '[{"cust_id":"'+this.has_hard_id+'","data":[{"Name":"clicktocall","fromno":"'+this.sip_login+'","tono":"'+tono+'"}]}]';
            this.websocket.send(socket_message);         
         }
     }, 
     (error)=>{
         console.log(error);
     });


   
}

}
